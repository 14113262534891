import React, { useState, useEffect } from "react";
import axios from "axios";
import './Style.css';

const Stats = () => {
    const [individualStats, setIndividualStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const [individualResponse] = await Promise.all([
                    axios.get(`${API_BASE_URL}/stats/individual`),
                ]);
                setIndividualStats(individualResponse.data);
            } catch (err) {
                console.error("Error fetching stats:", err);
            } finally {
                setLoading(false);
            }
        };
        fetchStats();
    }, []);

    return (
        <div>
            
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <h2>Individuell statistik</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Spelare</th>
                                <th>Vinster</th>
                                <th>Vinstprocent</th>
                                <th>Antal partners</th>
                            </tr>
                        </thead>
                        <tbody>
                            {individualStats.map((player) => (
                                <tr key={player.PlayerID}>
                                    <td>{player.PlayerName}</td>
                                    <td>{player.Wins}</td>
                                    <td>{player.WinPercentage}%</td>
                                    <td>{player.MostPartners}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};

export default Stats;
