import React, { useEffect, useState } from "react";
import axios from "axios";
import './Style.css';

const Ladder = () => {
    const [ladder, setLadder] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterGender, setFilterGender] = useState("M"); // Filter state
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

    useEffect(() => {
        const fetchLadder = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(`${API_BASE_URL}/ladder`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                        params: { gender: filterGender, t: Date.now() }, // Pass gender filter as query parameter  
                });
                console.log("Ladder data fetched:", response.data); // Debug log
                setLadder(response.data); // Store ladder data
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    console.error("Unauthorized: Token may be invalid or expired.");
                    localStorage.removeItem("token");
                    window.location.href = "/login";
                } else {
                    console.error("Error fetching ladder data:", error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchLadder();
    }, [filterGender, API_BASE_URL]); // Re-fetch when filterGender changes

    // Function to render movement as an icon
    const renderMovement = (movement) => {
        switch (movement) {
            case "Up":
                return <svg class="icon icon--up" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M12 4l-8 8h5v8h6v-8h5z"></path>
                </svg>; // Green up arrow
            case "Down":
                return <svg class="icon icon--down" viewBox = "0 0 24 24" aria-hidden="true" >
                    <path d="M12 20l8-8h-5v-8h-6v8h-5z"></path>
                </svg>; // Red down arrow
            case "Same":
                return <svg class="icon icon--hyphen" viewBox = "0 0 24 24" aria-hidden="true" >
                    <path d="M6 11h12v2H6z"></path>
                </svg>; // Black hyphen
            default:
                return null;
        }
    };

    return (
        <div>
            <h2>Individuell stege</h2>

            {/* Gender Filter Toggle */}
            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={filterGender === "F"}
                        onChange={(e) => setFilterGender(e.target.checked ? "F" : "M")}
                    />
                    Visa damspelare
                </label>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <table border="1" style={{ width: "100%", textAlign: "center" }}>
                    <thead>
                        <tr>
                            <th>Ranking</th>
                            <th>Spelare</th>
                            <th>Rating</th>
                            <th>Matcher</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ladder.map((player, index) => (
                            <tr key={index}>
                                <td>
                                    <div class="cell-content">
                                        <span class="number">{player.CurrentRank}</span> {renderMovement(player.Movement)}
                                    </div>
                                </td>
                                <td>{player.PlayerName}</td>
                                <td>{player.CurrentRating}</td>
                                <td>{player.MatchesPlayed}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Ladder;

