import React from "react";
import './Style.css';

const About = ({ onClose }) => {
    return (
        <div className="popup-overlay">
            <div className="popup">
                <div className="popup-header">
                    Om sajten
                </div>
                <div className="popup-content">
                    <p>Detta är en oberoende sajt, ämnad för padelspelare i Ale-Kungälv, som syftar till att visa aktuella rankingstegar och statistik, samt matcherna de baseras på.
                    </p><p>Kontakta padel@itissjodahl.se för mer information och för att lägga till dig som spelare.</p>
                </div>
                <div className="popup-footer">
                    <button onClick={onClose}>STÄNG</button>
                </div>
            </div>
        </div>
    );
};

export default About;    

   
