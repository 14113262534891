import React, { useState } from "react";
import axios from "axios";

const AdminAddPlayer = () => {
    const [formData, setFormData] = useState({
        PlayerName: "",
        Gender: "M",
        officialRating: "",
        Division: "",
        UnofficialRating: "",
    });
    const [message, setMessage] = useState("");

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem("token"); // Retrieve the token
            await axios.post(`${API_BASE_URL}/add-player`, formData, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage("Spelare registrerad!");
            setFormData({ PlayerName: "", Gender: "", officialPoints: "", division: "", unofficialRank: "" }); // Reset form
        } catch (error) {
            console.error("Error adding player:", error);
            setMessage("Error adding player. Please try again.");
        }
    };

    return (
        <div>
            <h2>Lägg till ny spelare</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Namn:
                    <input
                        type="text"
                        name="PlayerName"
                        value={formData.PlayerName}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Kön:
                    <select
                        name="Gender"
                        style={{ width: '120px', padding: '4px' }}
                        value={formData.Gender}
                        onChange={handleChange}
                        required
                    >
                        <option value="M">Man</option>
                        <option value="F">Kvinna</option>
                    </select>
                    
                </label>
                <label>
                    Officiella rankingpoäng:
                    <input
                        type="number"
                        name="officialPoints"
                        value={formData.officialPoints}
                        onChange={handleChange}
                        min="0"
                        max="100"
                        required
                    />
                </label>
                <label>
                    Division:
                    <input
                        type="number"
                        name="division"
                        value={formData.division}
                        onChange={handleChange}
                        //min="0"
                        //max="6"
                        required
                    />
                </label>
                <label>
                    Inofficiell ranking:
                    <input
                        type="number"
                        name="unofficialRank"
                        value={formData.unofficialRank}
                        onChange={handleChange}
                        min="1"
                        max="100"
                        required
                    />
                </label>
                <br />
                <button type="submit">Spara</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default AdminAddPlayer;
