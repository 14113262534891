import React from "react";

const MatchConfirmation = ({ formData, onConfirm, onCancel }) => {
    const { MatchDate, TeamA, Result, TeamB, MatchType, DetailedResult } = formData;
    const resultStyle = {
        color: formData.Result === "Win" ? "green" : "red",
        fontWeight: "bold",
    };
    const translatedResult = formData.Result === "Win" ? "VINST" : "FÖRLUST";



    return (
        <div className="confirmation-container">
            <h3>Stämmer denna registrering?</h3>
            <p>
                <strong>{TeamA}</strong>{" "}
                <span style={resultStyle}>{translatedResult}</span>{" "}
                <strong>{TeamB}</strong>
            </p>
            <p>
                <strong>Datum:</strong> {new Intl.DateTimeFormat('en-CA').format(new Date(MatchDate))}
            </p>
            <p>
                <strong>Matchtyp:</strong> {MatchType}
            </p>
            <p>
                <strong>Detaljerat resultat:</strong> {DetailedResult}
            </p>
            <div className="confirmation-buttons">
                <button onClick={onConfirm}>Ja, spara registrering</button>
                <button onClick={onCancel}>Avbryt</button>
            </div>
        </div>
    );
};

export default MatchConfirmation;
