import React, { useState, useEffect } from "react";
import axios from "axios";
import MatchConfirmation from "./MatchConfirmation.js";
import { useNavigate } from "react-router-dom";

const AddMatchForm = ({ onMatchAdded }) => {
    const [formData, setFormData] = useState({
        Player1ID: "",
        Player2ID: "",
        Opponent1ID: "",
        Opponent2ID: "",
        Result: "Win",
        DetailedResult: "",
        MatchType: "",
        MatchDate: "",
    });
    const [players, setPlayers] = useState([]); // Players list for dropdown
    const [showConfirmation, setShowConfirmation] = useState(false); // To toggle confirmation screen
    const [message, setMessage] = useState(""); // For any errors or status messages

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";
    const navigate = useNavigate();

    // Fetch players when the component mounts
    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/players`);
                setPlayers(response.data);
            } catch (error) {
                console.error("Error fetching players:", error);
            }
        };
        fetchPlayers();
    }, [API_BASE_URL]);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission (show confirmation screen)
    const handleSubmit = (e) => {
        e.preventDefault();

        // Find player names for TeamA and TeamB
        const TeamA = `${players.find((p) => p.PlayerID === parseInt(formData.Player1ID))?.PlayerName || "Unknown"} & ${players.find((p) => p.PlayerID === parseInt(formData.Player2ID))?.PlayerName || "Unknown"
            }`;
        const TeamB = `${players.find((p) => p.PlayerID === parseInt(formData.Opponent1ID))?.PlayerName || "Unknown"} & ${players.find((p) => p.PlayerID === parseInt(formData.Opponent2ID))?.PlayerName || "Unknown"
            }`;

        setShowConfirmation(true); // Show the confirmation screen

        // Pass formatted data to the MatchConfirmation component
        setFormData((prevData) => ({
            ...prevData,
            TeamA,
            TeamB,
        }));
        console.log("TeamA:", TeamA, "TeamB:", TeamB);
    };

    // Handle confirmation (finalize match registration)
    const handleConfirm = async () => {
        try {
            const token = localStorage.getItem("token"); // Retrieve the token
            await axios.post(`${API_BASE_URL}/add-match`, formData, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setMessage("Match successfully registered! Redirecting to the ladder...");

            setTimeout(() => {
                setShowConfirmation(false); // Close the confirmation page
                navigate("/"); // Redirect to the Ladder page
            }, 2000); // Adjust timing if needed
        } catch (error) {
            console.error("Error registering match:", error);
            setMessage("Error registering match. Please try again.");
        }
    };

    // Handle cancellation (go back to form)
    const handleCancel = () => {
        setShowConfirmation(false);
    };


    return (
        <div>
            {showConfirmation ? (
                <MatchConfirmation
                    formData={{
                        MatchDate: formData.MatchDate,
                        TeamA: formData.TeamA,
                        Result: formData.Result,
                        TeamB: formData.TeamB,
                        MatchType: formData.MatchType,
                        DetailedResult: formData.DetailedResult
                    }}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            ) : (
            //<h2>Matchregistrering</h2>
            <form onSubmit={handleSubmit}>
                
                        {/* Player 1 Dropdown */}
                        <label style={{ textAlign: 'left', display: 'block' }}>
                            Lag A*
                                        <br />
                     <select
                        name="Player1ID"
                        style={{ width: '250px', padding: '5px' }}
                        value={formData.Player1ID}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Välj Spelare 1</option>
                        {players.map((player) => (
                            <option key={player.PlayerID} value={player.PlayerID}>
                                {player.PlayerName}
                            </option>
                        ))}
                                        </select>
                                        <br />
                    <select
                        name="Player2ID"
                        style={{ width: '250px', padding: '5px' }}
                        value={formData.Player2ID}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Välj Spelare 2</option>
                        {players.map((player) => (
                            <option key={player.PlayerID} value={player.PlayerID}>
                                {player.PlayerName}
                            </option>
                        ))}
                    </select>
                            </label>
                        <div style={{ display: 'flex', alignitems: 'center', gap: '10px' }}>
                           
                                    {/* Result Dropdown */}
                                    <label style={{ textAlign: 'left', display: 'block' }}>
                                        Resultat*
                                        <br />
                                        <select
                                            name="Result"
                                            style={{ width: '120px', padding: '4px' }}
                                            value={formData.Result}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="Win">Vinst</option>
                                            <option value="Lose">Förlust</option>
                                        </select>
                                    </label>
                              
                                    {/* Detailed result */}
                                    <label style={{ textAlign: 'left', display: 'block' }}>
                                        Detaljerat resultat
                                        <br />
                                        <input
                                            type="text"
                                            style={{ width: '120px', padding: '4px' }}
                                            name="DetailedResult"
                                            value={formData.DetailedResult}
                                            onChange={handleChange}
                                            
                                        >
                                        </input>
                                    </label>
                                
                                </div>
                                {/* Opponent 1 Dropdown */}
                                    <label style={{ textAlign: 'left', display: 'block' }}>
                                    Lag B*
                                    <br />
                                    <select
                                        name="Opponent1ID"
                                        style={{ width: '250px', padding: '5px' }}
                                        value={formData.Opponent1ID}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Välj Motståndare 1</option>
                                        {players.map((player) => (
                                            <option key={player.PlayerID} value={player.PlayerID}>
                                                {player.PlayerName}
                                            </option>
                                        ))}
                                        </select>
                                        <br />
                                    {/* Opponent 2 Dropdown */}
                                    
                                    <select
                                        name="Opponent2ID"
                                        style={{ width: '250px', padding: '5px' }}
                                        value={formData.Opponent2ID}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Välj Motståndare 2</option>
                                        {players.map((player) => (
                                            <option key={player.PlayerID} value={player.PlayerID}>
                                                {player.PlayerName}
                                            </option>
                                        ))}
                                    </select>
                                    </label>
                              
                                    {/* Match Date */}
                                    <label style={{ textAlign: 'left', display: 'block' }}>
                                        Matchdatum*
                                        <br></br>
                                        <input
                                            type="date"
                                            style={{ width: '150px', padding: '5px' }} 
                                            name="MatchDate"
                                            value={formData.MatchDate}
                                            onChange={handleChange}
                                            required
                                        />
                                    </label>
                              
                                    {/* Match type */}
                                    <label style={{ textAlign: 'left', display: 'block' }}>
                                        Matchtyp*
                                        <br />
                                        <select
                                            name="MatchType" // Ensure the name matches the formData key
                                            style={{ width: '180px', padding: '5px' }}
                                            value={formData.MatchType} // Bind to the formData state
                                            onChange={handleChange} // Reuse the existing handleChange function
                                            required
                                        >
                                            <option value="" disabled>
                                                Välj typ av match
                                            </option>
                                            <option value="Träningsmatch">Träningsmatch</option>
                                            <option value="Klubbtävling">Klubbtävling</option>
                                            <option value="Sanktionerad">Sanktionerad</option>
                                        </select>
                                    </label>

                <button type="submit">Registrera match</button>
            </form>
            //{message && <p style={{ color: "green" }}>{message}</p>}
            )}
        </div>
    );
};

export default AddMatchForm;

